<template>
  <div v-if="page" class="page-container">
    <template v-if="centraCampaignSite">
      <client-only>
        <component
          :is="block.component"
          v-for="block in page.content.main_content"
          :key="block.id"
          v-editable="block"
          v-bind="block"
          :page-name="page.content.breadcrumbName || page.name"
        />
      </client-only>
    </template>
    <template v-else>
      <component
        :is="block.component"
        v-for="block in page.content.main_content"
        :key="block.id"
        v-editable="block"
        v-bind="block"
        :page-name="page.content.breadcrumbName || page.name"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StoryBlokPreview from '~/mixins/storyblok-live-preview'
import serverLogger from '~/server/server-logger'

export default {
  name: 'Storyblok',
  mixins: [StoryBlokPreview],
  props: {
    marketId: {
      type: String,
      required: true,
    },
    pricelistId: {
      type: String,
      required: true,
    },
    languageCode: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      meta: {
        title: '',
        meta: [],
        canonical: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      getStoryById: 'storyblok/getStoryById',
      settings: 'storyblok/settings',
      activeCampaign: 'campaign/activeCampaign',
    }),
    page () {
      return this.getStoryById(this.id) || this.data
    },
    centraCampaignSite () {
      return this.page.content.centraCampaignSite
    },
  },
  created () {
    return this.setMeta()
  },
  serverPrefetch () {
    return this.fetchCampaignSite()
  },
  mounted () {
    this.gtm_trackPageImpression(this.meta.title, this.page.slug === 'site' ? 'home' : 'other')
    this.validateCampaignSite()
  },
  methods: {
    fetchCampaignSite () {
      const campaignCode = this.page.content.centraCampaignSite
      serverLogger.debug('current campaign code is ', campaignCode)
      if (campaignCode) {
        return this.$store.dispatch(
          'campaign/initializeCampaignByUri',
          campaignCode
        )
      }
    },
    validateCampaignSite () {
      if (this.activeCampaign) {
        this.$backendApi
          .get(`/campaign-site/${this.activeCampaign}`)
          .catch((error) => {
            if (error.response?.status === 404) {
              this.$store.dispatch('campaign/removeActiveCampaign')
            }
          })
      }
    },
    setMeta () {
      if (this.page && this.page.content.seo) {
        const blok = this.page.content
        const description =
          blok.seo.description || this.settings.default_meta_description
        const canonical =
          this.$config.baseUrl +
          (blok.canonical
            ? this.$u(blok.canonical)
            : this.$route.path?.toLowerCase())
        const meta = [
          {
            hid: 'description',
            name: 'description',
            content: description,
          },
          {
            hid: 'og:title',
            property: 'og:title',
            content: blok.seo.og_title || blok.seo.title || this.page.name,
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: blok.seo.og_description || description,
          },
          { hid: 'og:url', property: 'og:url', content: canonical },
          { hid: 'og:type', property: 'og:type', content: 'website' },
          {
            hid: 'og:site_name',
            property: 'og:site_name',
            content: 'Stronger',
          },
        ]

        const robots = blok.metaRobots || null
        if (robots && robots.length) {
          meta.push({
            hid: 'robots',
            name: 'robots',
            content: robots.join(','),
          })
        }

        const ogImage =
          blok.seo.og_image || this.settings.openGraphImage?.filename || null
        if (ogImage) {
          const resizedImage = this.$imgproxy.transform(
            ogImage,
            'preset:sharp/rs:fill/w:1200/h:630/g:sm/q:70'
          )
          meta.push({
            hid: 'og:image',
            content: resizedImage,
            property: 'og:image',
          })
        }

        const metaTitle = blok.seo.title || this.page.name
        this.meta.title =
          this.settings.meta_title_prefix +
          metaTitle +
          this.settings.meta_title_suffix
        this.meta.meta = meta
        this.meta.canonical = canonical
      }
    },
    getUrl () {
      if (this.$store.state.route.query._storyblok) {
        // Browsing in SB
        return this.$store.state.route.query._storyblok
      }

      let route = this.$route.path.replace(/^\//, '')
      route = route === '' ? 'home' : route
      route = route.replace(/^[\w-]+\/?/, 'site/')
      return route
    },
  },
  head () {
    return {
      title: this.meta.title,
      meta: this.meta.meta,
      link: [
        {
          rel: 'canonical',
          href: this.meta.canonical,
        },
      ],
    }
  },
}
</script>
